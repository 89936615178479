import React, { useEffect, useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { isEmpty } from '@enotarylog/ramda';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useStyles } from './styles';
import { obscurePhone, obscureEmail } from 'document-viewer/src/utils/obscure';
import { AuthStates, TwoFactorProps, TwoFactorCodeTypes } from '../types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Checkbox,DialogTitle, DialogContentText, FormGroup, Grid } from '@material-ui/core';
import { DialogContent, DialogActions } from 'document-viewer/src/components/Dialog';
import { DocumentPreview } from '@enotarylog/pdftron-webviewer/viewer/components/DocumentPreview';
import { isMobileDevice } from 'document-viewer/src/utils/agents';
import clsx from 'clsx';

export default function TwoFactorAuth({
  name,
  phone,
  email,
  codeType,
  sendCode,
  currentProcess,
  checking,
  checkCodeValid,
  signatureAndInitialsInOneStep,
  participant,
  handleAttemptsCallBack
}: TwoFactorProps) {
  const CDN_URL = process.env.NX_CDN_URL;
  const classes = useStyles({});

  const formik = useFormik({
    initialValues: {
      code: '',
      checkedeSignConsent: false,
      checkedTermsConditionPrivacyPolicy: false,
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .max(6, 'Must be 6 characters')
        .min(6, 'Must be 6 characters')
        .required('Required'),
      ...(!signatureAndInitialsInOneStep && {
        checkedeSignConsent: Yup.boolean().oneOf([true]),
        checkedTermsConditionPrivacyPolicy: Yup.boolean().oneOf([true]),
      }),
    }),
    onSubmit: ({ code }, helpers) => {
      checkCodeValid(code);
      helpers.resetForm();
    },
  });

  // Send an SMS code as soon as this is rendered for the first time
  useEffect(() => {
    if (currentProcess === AuthStates.Initial && !checking) {
      sendCode(codeType);
    }
  }, [currentProcess, checking, sendCode]);

  const [passwordAttempts, setPasswordAttempts] = useState(participant.authenticationFailures);

  const submitCheck = useCallback(() => {
    setPasswordAttempts(passwordAttempts + 1);
    handleAttemptsCallBack(passwordAttempts + 1);
  }, [passwordAttempts, setPasswordAttempts]);

  const isMobile = isMobileDevice();

  const tryAgain = currentProcess === 'TRY_AGAIN';
  const canSubmit = ['INITIAL','TRY_AGAIN', 'ERROR_SENDING_CODE'].includes(currentProcess);
  const maxAttempts = +process.env.NX_MAX_AUTH_ATTEMPTS
  const attemptsRemaining = maxAttempts-passwordAttempts;

  let errorMsg: any;

  if (attemptsRemaining===2) {
    errorMsg=
      <div>
        You have
        <b> two more attempts left </b>
        before the session will be locked.
      </div>;
  }
  else {
    errorMsg=
      <div>
        You have
        <b> one more attempt left </b>
        before the session will be locked.
      </div>;
  };
  
  return (
    <>
      {(attemptsRemaining>0) &&
        <Grid
          container
          justifyContent='center'
          direction='column'
          className={classes.flexGrow}
        >
          <DialogContent
            className={tryAgain ? clsx(classes.verticallySpaced, classes.centeredListStyle, classes.dialogContentClass) : clsx(classes.verticallySpaced, classes.centeredListStyle)}
          >
            {!tryAgain && (
              <>
                <DialogTitle
                  className={classes.noPadding}
                >
                  Let&apos;s confirm your identity
                </DialogTitle>
                <p>
                  <strong>{name}</strong>
                  , we need to make sure it&apos;s you before signing.
                </p>
                <p
                  className={classes.codeMsg}
                >
                  {currentProcess === 'ERROR_SENDING_CODE' ? 'We were unable to send a code to ' : 'We just sent a code to '}
                  <strong>{codeType === TwoFactorCodeTypes.Sms ? obscurePhone(phone) : obscureEmail(email)}</strong>
                  .
                </p>
              </>
            )}
            {
              tryAgain || currentProcess === 'BLOCKED' ?
                <DialogContentText className={classes.dialogContentText} >
                  <p className='mt-1'>
                    The response you have entered is incorrect.
                    <br></br>
                    {errorMsg}
                  </p>
                </DialogContentText>
                :
                null
            }

            {currentProcess === 'CODE_SENT' &&
              <TextField
                error={!isEmpty(formik.errors.code) && formik.submitCount > 0}
                placeholder='Type code'
                name='code'
                value={formik.values.code}
                onChange={formik.handleChange}
                {...formik.getFieldProps('code')}
                helperText={!isEmpty(formik.errors.code) && formik.submitCount > 0 && formik.errors.code}
                inputProps={{ minLength: 6, maxLength: 6 }}
              />}
          </DialogContent>
        </Grid>}
      {(attemptsRemaining>0) &&
        <DialogActions className={!tryAgain ? clsx(classes.dialogActions, classes.containerBackground) : classes.dialogActions}>
          {canSubmit && (
            <Button
              onClick={() => sendCode(codeType)}
              variant='contained'
              color='primary'
              className={classes.button}
            >
              {currentProcess !== 'INITIAL' ? (!tryAgain ? 'TRY AGAIN' : 'RETRY') : 'SUBMIT'}
            </Button>
          )}

          {currentProcess === 'CODE_SENT' && (
            <form onSubmit={formik.handleSubmit}>
              <Box
                display='flex'
                justifyContent='space-between'
                flexDirection='column'
                className={classes.checkboxesContainer}
              >
                {
                  !signatureAndInitialsInOneStep ?
                    <>
                      <FormGroup className={classes.marginTopSm}>
                        <FormControlLabel
                          className={classes.formControlLabel}
                          control={
                            <div className={classes.checkboxContainer}>
                              <Checkbox
                                checked={formik.values.checkedTermsConditionPrivacyPolicy}
                                onChange={formik.handleChange}
                                {...formik.getFieldProps('checkedTermsConditionPrivacyPolicy')}
                              />
                            </div>
                          }
                          label={
                            <>
                              By clicking the checkbox, you agree to eNotaryLog's
                              {' '}
                              <a
                                href={CDN_URL+"/enl-public-files/static/pdf/tc.pdf"}
                                target='_blank'
                                rel='noreferrer noopener'
                              >
                                Terms and Conditions
                              </a>
                              {' '}
                              and
                              {' '}
                              <a
                                href={CDN_URL+"/enl-public-files/static/pdf/privacy.pdf"}
                                target='_blank'
                                rel='noreferrer noopener'
                              >
                                Privacy Policy
                              </a>
                            </>
                          }
                        />
                      </FormGroup>
                      <FormGroup className={classes.marginTopSm}>
                        <FormControlLabel
                          className={classes.formControlLabel}
                          control={
                            <div className={classes.checkboxContainer}>
                              <Checkbox
                                checked={formik.values.checkedeSignConsent}
                                onChange={formik.handleChange}
                                {...formik.getFieldProps('checkedeSignConsent')}
                              />
                            </div>
                          }
                          label={
                            <>
                              By clicking the checkbox, you agree to eNotaryLog's
                              {' '}
                              <a
                                href={CDN_URL+"/enl-public-files/static/pdf/esign_policy.pdf"}
                                target='_blank'
                                rel='noreferrer noopener'
                              >
                                eConsent to use Electronic Signatures, Records and Communications.
                              </a>
                            </>
                          }
                        />
                      </FormGroup>
                    </>
                    :
                    null
                }
                { !signatureAndInitialsInOneStep && !tryAgain ? <br/> : null }
                {
                  !signatureAndInitialsInOneStep && !tryAgain && !isMobile ?
                    <div
                      style={{
                        width: '100%',
                        height: '350px',
                      }}
                    >
                      <DocumentPreview
                        url={CDN_URL+"/enl-public-files/static/pdf/esign_policy.pdf"}
                      />
                    </div> : null
                }
                <div className={clsx(classes.centered, classes.centeredListStyle)}>
                  <Button
                    className={classes.button}
                    disabled={checking || Object.keys(formik.errors).length > 0}
                    variant='contained'
                    type='submit'
                    color='primary'
                    onClick={() => submitCheck()}
                  >
                    Check code
                  </Button>
                </div>
              </Box>
            </form>
          )}

          {currentProcess === 'CODE_VALIDATED' && <p className='text-success mt-1'>Correct Code</p>}
        </DialogActions>}
      {(attemptsRemaining===0) &&
        <>
          { submitCheck() }
          { checkCodeValid(formik.values.code) }
        </>}
    </>
  );
}
