import React from 'react';
import {
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  DialogContent,
} from '@material-ui/core';
import clsx from 'clsx';
import { useSetSigInitStyles, useTypeItInStyles } from './styles';
import DrawIt from './drawIt';
import FontAndColorSelectors from './FontAndColorSelectors';
import TypeItIn from './typeIn';
import { DocumentPreview } from '@enotarylog/pdftron-webviewer/viewer/components/DocumentPreview';

export default function CombinedSetSigInit({
  color,
  setColor,
  onChange,
  activeTab,
  onActiveTabChanged,
  open,
  participantName,
  fontFamilies,
  fontFamily,
  setFontFamily
}) {
  const classes = useSetSigInitStyles();
  const typeItInClasses = useTypeItInStyles();
  const canChooseColor = true; // TODO: use something in style to control this
  const canChooseFont = activeTab === 0 && fontFamilies.length;
  
  const CDN_URL = process.env.NX_CDN_URL
  return (
    <DialogContent
      className={clsx({
        [classes.hidden]: !open,
      })}
    >
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        className={typeItInClasses.topRow}
      >
        <Grid
          item
          md={canChooseColor ? 4 : 9}
        >
          <RadioGroup
            row
            aria-label='Typed or drawn signature'
            value={activeTab}
            onChange={(e) => onActiveTabChanged(+e.target.value)}
          >
            <FormControlLabel
              value={1}
              control={<Radio />}
              label='Draw'
            />
            <FormControlLabel
              value={0}
              control={<Radio />}
              label='Type'
            />
          </RadioGroup>
        </Grid>
        {canChooseFont || canChooseColor ? (
          <Grid
            item
            md={canChooseColor ? 8 : 3}
          >
            <FontAndColorSelectors
              justifyContent={canChooseColor && canChooseFont ? 'space-between' : 'flex-end'}
              canChooseFont={canChooseFont}
              font={fontFamily}
              setFont={setFontFamily}
              fontChoices={fontFamilies}
              canChooseColor={canChooseColor}
              color={color}
              setColor={setColor}
            />
          </Grid>
        ) : null}
      </Grid>
      {activeTab === 0 ? (
        <TypeItIn
          onChange={(type, data) => onChange(type, 'typeitin', data)}
          color={color}
          setColor={setColor}
          participantName={participantName}
          fontFamilies={fontFamilies}
          fontFamily={fontFamily}
          setFontFamily={setFontFamily}
          isVisible={open}
        />
      ) : (
        <Grid
          container
          justifyContent='space-between'
        >
          <Grid
            xs={8}
            item
            className={classes.rightSideSpacingMdUp}
          >
            <DrawIt
              type='signature'
              combinedStyle
              onChange={(data) => onChange('signature', 'drawit', data)}
              color={color}
              setColor={setColor}
            />
          </Grid>
          <Grid
            xs={4}
            style={{ marginTop:'auto' }}
            item
          >
            <DrawIt
              type='initials'
              combinedStyle={true}
              onChange={(data) => onChange('initials', 'drawit', data)}
              color={color}
              setColor={setColor}
            />
          </Grid>
        </Grid>
      )}
      <div>
        <p className={classes.legalese}>
          Customer agrees to all terms and conditions contained in the eNotaryLog
          {' '}
          <a
            href={CDN_URL+"/enl-public-files/static/pdf/tc.pdf"}
            target='_blank'
            rel='noreferrer noopener'
          >
            Terms and Conditions
          </a>
          ,
          the
          {' '}
          <a
            href={CDN_URL+"/enl-public-files/static/pdf/privacy.pdf"}
            target='_blank'
            rel='noreferrer noopener'
          >
            Privacy Policy
          </a>
          {' '}
          and the
          {' '}
          <a
            href={CDN_URL+"/enl-public-files/static/pdf/esign_policy.pdf"}
            target='_blank'
            rel='noreferrer noopener'
          >
            eConsent to use Electronic Signatures, Records and Communications
          </a>
          {' '}
          which can be found for review on the eNotaryLog website.
        </p>
        <p className={classes.legalese}>
          By clicking "ACCEPT", you acknowledge that you have read and affirmatively agree to the terms set forth in this Agreement.
        </p>
      </div>
      <div
        style={{
          width: '100%',
          height: '350px',
        }}
      >
        <DocumentPreview
          url={CDN_URL+"/enl-public-files/static/pdf/esign_policy.pdf"}
        />
      </div>
    </DialogContent>
  );
}
