import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import _ from 'lodash';
import loadImg from '../lib/helpers/loadImg';
import license from '../../../license';


function blobToDataURL(blob: Blob) {
  return new Promise((res) => {
    const a = new FileReader();

    a.onload = function onload(e) {
      return res(e.target!.result);
    };

    a.readAsDataURL(blob);
  });
}

export interface CertViewerProps {
  pdf?: any;
  pdftronServer?: any;
  signatures?: any;
  currentUser?: any;
  unbindEvents?: any;
  onClose?: any;
  onSubmit?: any;
  images?: any;
}

export interface CertViewerState {
  loaded: boolean;
}

class CertViewer extends Component<CertViewerProps, CertViewerState> {
  public viewerRef: any;
  public inputRef: any;
  public docViewer: any;
  public annotManager: any;
  public instance: any;
  public CoreControls: any;

  constructor(props: CertViewerProps) {
    super(props);

    this.viewerRef = React.createRef();
    this.inputRef = React.createRef();
    this.docViewer = null;
    this.annotManager = null;
    this.instance = null;
    this.CoreControls = null;
    this.state = { loaded: false };
  }


  async componentDidMount() {
    const { default: WebViewer } = await import('@pdftron/webviewer');
    const winstance = await WebViewer({
      path: '/lib',
      fullAPI: true,
      css: '/wv-configs/webviewer-styles/certPreviewModal.css',
      initialDoc: (_.isString(this.props.pdf)) ? this.props.pdf : undefined,
      // @ts-expect-error l prop doesn't exist
      l: license,
      enableAnnotations: true,
      pdftronServer: this.props.pdftronServer,
      disabledElements: [
        'stickyToolButton',
        'leftPanel',
        'freeHandToolGroupButton',
        'menuButton',
        'miscToolGroupButton',
        'leftPanelButton',
        'searchButton',
        'textToolGroupButton',
        'viewControlsButton',
        'linkButton',
        'shapeToolGroupButton',
        'freeTextToolButton',
        // 'signatureToolButton',
        'eraserToolButton',
        'thumbnailControl',
        'outlinesPanel',
        'outlinesPanelButton',
        'thumbnailsPanelButton',
        'leftPanelTabs',
      ],
      config: '/wv-configs/vanilla-config.js',
    }, this.viewerRef.current);


    winstance.docViewer.one('ready', async (instance) => {
      instance.hotkeys.off();
      instance.hotkeys.on('AnnotationEdit');


      // await createCertModalHeader(instance);
      // at this point, the viewer is 'ready'
      // call methods from instance, docViewer and annotManager as needed
      this.instance = instance;


      const {
        docViewer, annotManager, CoreControls,
      } = instance;

      this.docViewer = docViewer;
      this.annotManager = annotManager;
      this.CoreControls = CoreControls;


      // await instance.loadDocument(this.props.pdf);
      this.docViewer.on('annotationsLoaded', async () => {
        // all annotations are available
        this.setState({ loaded: true });

        // load signatures if available
        if (this.props.signatures?.[this.props.currentUser]) {
          await this.instance.loadSignatures(this.props.signatures[this.props.currentUser]);
        }
      });

      if (!_.isString(this.props.pdf)) {
        await winstance.loadDocument(this.props.pdf);
      }
    });
    winstance.docViewer.trigger('initReady');
  }

  componentWillUnmount = async () => {
    if (_.isFunction(this.props.unbindEvents)) {
      await this.props.unbindEvents();
    }

    if (window.getInstance && this.viewerRef.current) {
      // console.log(this.viewerRef);
      const instance = window.getInstance(this.viewerRef.current);


      if (instance) {
        instance?.docViewer?.trigger('documentUnloaded');
        instance.dispose();
      }
    }
  }

  createSigAnnot = (type: any, dataUrl: any) => {
    const sigFh = new this.instance.Annotations.StampAnnotation();

    sigFh.ImageData = dataUrl;
    sigFh.Subject = 'Signature';
    sigFh.Width = 200;
    sigFh.Height = 79.18999999999994;
    sigFh.MaintainAspectRatio = true;
    sigFh.setCustomData('type', type);

    return sigFh;
  }


  render() {
    return (
      <>
        <Modal.Body>
          <div
            className='webviewer cert'
            ref={this.viewerRef}

          />
        </Modal.Body>
        <Modal.Footer
          style={{
            borderTop: '1px solid',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            disabled={!this.state.loaded}
            onClick={this.props.onClose}
          >
            Close
          </Button>
          <Button
            disabled={!this.state.loaded}
            onClick={async () => {
              /* converts pdf to png dataurl */
              const PDFNet = this.instance.PDFNet;

              await PDFNet.initialize(license);

              // export annotations as xml
              const xfdfString = await this.annotManager.exportAnnotations({ widgets: true, links: false, fields: true });
              const doc = this.docViewer.getDocument();
              const options = { xfdfString };

              // get the file data w/ annotations baked in
              const data = await doc.getFileData(options);
              const arr = new Uint8Array(data);


              const pdoc = await PDFNet.PDFDoc.createFromBuffer(arr);

              const pdfDraw = await PDFNet.PDFDraw.create(184);

              const itr = await pdoc.getPageIterator(1);
              const currPage = await itr.current();
              const pngBuffer = await pdfDraw.exportStream(currPage, 'PNG');
              const pngblob = new Blob([pngBuffer], { type: 'image/png' });
              const dataurl = await blobToDataURL(pngblob);


              this.props.onSubmit({ dataurl });
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

export interface CertPdfModalProps {
  show?: boolean;
  onHide?: () => void;
  type?: string;
  images?: any;
  pdf?: string;
  onSubmit?: any;
  pdftronServer?: any;
  pdftronLicense?: any;
  currentUser?: any;
  signatures?: any;
}

export default function CertPdfModal(props: CertPdfModalProps) {
  const CDN_URL = process.env.NX_CDN_URL
  return (
    <Modal
      size='xl'
      show={props.show}
      onHide={props.onHide}
      className='pdfStaticContainer'
    >
      <Modal.Header>
        {props.type}
        Certificate
      </Modal.Header>

      <CertViewer
        {...props}
        images={props.images}
        pdf={props.pdf || CDN_URL+'/enl-public-files/certs/acknowledgement.pdf'}
        onSubmit={async ({ dataurl }: any) => {
          const img = await loadImg(dataurl);

          return props.onSubmit({ img, dataUrl: dataurl });
        }}
        onClose={props.onHide}
      />
    </Modal>
  );
}
